.updateBtnModel{
    padding:0.2rem 1.1rem!important;
    border-radius:10px!important;
    margin-top:8px!important;
    border: none!important;
    font-size:15px!important;
    font-weight:500!important;
    background-color: #013191!important;
    color: white!important;
    margin-top:0.3rem 0.2rem!important;
    z-index:0;
}
.updateBtnModel:hover{
    cursor: pointer!important;
    background-color:rgb(228, 227, 227)!important;
    color:black!important;
}

.UpdateAnnouncment{
    background-color: #013191!important;
    color: white!important;
    /* margin-bottom:1.5rem!important; */
    border-radius: 12px!important;
    border:0!important;
    padding:0.4rem 1rem!important;
    margin-top:0.5rem!important;
    font-size:16px!important;
    font-weight: 600!important;
}
.UpdateAnnouncment:hover{
    background-color: #d4d4d4!important;
    color: rgb(0, 0, 0)!important;
    /* margin-bottom:1.5rem!important;
    border-radius: 12px!important; */
}