.sidebar {
  background-color: #013191;
  display: block;
  min-height:100vh;
}
.sidebar1 {
  background-color: #013191;
  display: none;
}
.topSection{
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid red; */
  padding:20px 5px;

}
.logo{
  /* border:1px solid rgb(197, 190, 190) */
  font-size:20px;
  font-weight: 500;
  padding-left:5px;
  color: white;
}
.backArrow{
  font-size:30;
  font-weight:bold;
  z-index:5
}

.userLogin {
  padding: absolute !important;
  bottom: 0% !important;
  /* border:1px solid white; */
  margin-top: 1rem;
  padding-left: 1rem;
  color: white;
}

.user:hover {
  border: 1px solid #40e0cf;
  width: 10rem;
  border-radius: 12px;
  box-shadow: 0px 2px 2px #40e0cf;
  cursor: pointer;

}
.logout {
  margin-right: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: large;
}
.req {
  margin-left: 0.4rem;
  cursor: pointer;
  font-weight: 500;
  font-size: large;
}
.logoutandIcon {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  color: white;
  /* border: 1px solid black; */
}
.logoutIcon {
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  font-size: large;
  cursor: pointer;
  color: white;
}
.logoutandIcon:hover {
  color: #40e0cf;
}
.updateProfileBtn{
  padding:10px 15px;
  margin-top:5px;
  outline:none;
  border:none;
  background-color: #013191;
  color: white;
  font-size: 15px;
  font-weight:600;
  letter-spacing:1px;
  text-transform: uppercase;
  border-radius: 12px;
}
.updateProfileBtn:hover{
  background-color:rgb(228, 227, 227);
  color:black;
  cursor: pointer;
}



@media screen and (max-width: 580px) {
  .toggleNavBar {
    display:block;
  }
  .sidebar {
    display: none!important;
    
  }
  .sidebar1 {
    display: block!important;
  }
}

