.css-1wnsr1i {
    /* background-image: linear-gradient(rgba(82, 87, 90, 0.23), #06283aed),url('../../assets/update.jpg'); */
    /* background:linear-gradient(to right, rgb(219, 222, 224),#e3e9ee)!important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 35%!important;
    border: 0!important; */
    /* filter: blur(0.2px);
  -webkit-filter: blur(0.2px); */
    /* backface-visibility: ; */
}
.updateFaq {
    padding:0.4rem 1.3rem!important;
    border-radius:10px!important;
    /* margin-top:8px!important; */
    border: none!important;
    /* background-color: #2F1781!important; */
    color: white!important;
    /* margin-bottom:1rem!important; */
    /* margin-left: 1rem!important; */
    /* background:linear-gradient(to right, rgb(168, 168, 168),rgba(21, 111, 156, 0.929))!important; */
    background-color: #2F1781!important;
    /* border: 1px solid black!important; */
    margin-left:0.2rem!important;
    margin-top:0.3rem!important;
}
.updateFaq:hover{
    cursor: pointer!important;
    background-color:rgb(228, 227, 227)!important;
    color:black!important;
}
.updateFaqQA{
    padding:0.4rem 1.3rem!important;
    border-radius:10px!important;
    margin-top:8px!important;
    border: none!important;
    /* background-color: #2F1781!important; */
    color: white!important;
    margin-bottom:1rem!important;
    margin-left: 1.5rem!important;
    /* background:linear-gradient(to right, rgb(168, 168, 168),rgba(21, 111, 156, 0.929))!important; */
    background-color: #013191!important;
}
.updateFaqQA:hover{
    cursor: pointer!important;
    background-color:rgb(228, 227, 227)!important;
    color:black!important;
}

/* .css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
background-color: blue!important;
} */
label{
    font-size:15px!important;
    font-weight:500!important;
    color:rgb(13, 51, 128)!important;
  }
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    font-weight: 600!important;
    padding:0 1rem!important;
    width:29rem!important;
    border-radius: 13px!important;
} */
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    color: rgb(106, 93, 160)!important;
    font-weight: 800!important;
} */
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width:32rem !important;
    color: red!important;
    border: 1px solid yellow!important;
  } */