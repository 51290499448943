.question {
  border: 0 !important;
  margin: 1rem 0.5rem !important;
  text-align: center;
  padding: 0.7rem 1rem !important;
  border-radius: 12px;
  font-weight: 600 !important;
  font-size:1.3rem!important;
  cursor: pointer;
}
.answer {
  border: 0 !important;
  font-size:1.1rem!important;
  font-weight: 400 !important;
  background-color: #d1e1f0 !important;
  margin: 1rem 0.5rem !important;
  padding: 0.7rem 1rem !important;
}
.faqLink {
  border: 0 !important;
  font-weight: 400 !important;
  margin-Top:0.5rem!important;
  background-color: #d1e1f0 !important;
  font-size:1.1rem!important;
  margin: 1rem 0.5rem !important;
  padding: 0.7rem 1rem !important;
  word-wrap: break-word!important;
  white-space: nowrap!important; 
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}