.mainDivUser {
  background-color: rgb(240, 240, 241);
}
.userTitle {
  font-weight: 700;
  margin-left: 0.7rem;
  font-size: 1.5rem;
}
.searchBoxandTabsU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
}
.tabsMainU {
  display: flex;
  padding-bottom: 1rem;
}
.tabBtnU {
  font-weight: 500 !important;
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
  font-size: larger !important;
  margin:0 5px;
}
.tabBtnU:hover {
  cursor: pointer;
}
table {
  padding: 0.2rem 0;
  width: 100% !important;
  border-collapse: collapse;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.5rem;
}

th {
  text-align: left;
  font-weight: 800;
  /* padding-left:5px; */
  border-bottom: 1px solid #ddd;
  padding: 0.2rem 0;
  padding-left:5px;
  font-size: 20 px;
}
td {
  /* padding-left: 15px !important; */
  padding: 0.2rem 0;
  font-weight: 400 !important;
  padding-left:0px!important;
  /* padding-left:7px; */
  padding-right: 4px;
  border-bottom: 1px solid #ddd;
  font-weight: 200;
  font-size: 16px;
}
.idU {
  text-align: center!important;
}


.nameU {
  max-width: 80px !important;
  word-wrap: break-word;
  text-align: center!important;
}
.designationU {
  max-width: 100px !important;
  word-wrap: break-word;
  text-align: center!important;
}
.addressU {
  max-width: 100px !important;
  word-wrap: break-word;
  text-align: center!important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionsU{
width:20%;
/* display: flex;
align-items: center;
justify-content: center; */
/* background-color: red; */
}
.ActioniconUser {
  width:100%;
  border-radius: 12px;
  background-color: #bad1c2;
  justify-content:center;
  text-align: center;
  display: flex;
  /* width:0%; */
}
label {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgb(13, 51, 128) !important;
}
.promotDemot{
  display: flex;
  align-items: center;
}
.pdBtn{
  width:80px;
  margin:2px 0;
  padding:7px 0;
  font-weight:500!important;
  font-size: 16px;
   border: none;
   border-radius:8px;
   background-color:#013191;
   color: white;
   cursor: pointer;
}
.promotionLabel{
  color: #013191;
  font-size:17px!important;
  font-weight: 500;
  margin-left: 10px;
  margin:10px 10px;
  cursor: pointer;
}
.promotionInput{
  margin:10px 0;
  cursor: pointer;
}
.refreshUser{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top:25px;
}
.noReq{
  text-align: center;
  font-size: 30px;
  padding-right:20px;
}
.userDetails{
  overflow-x: auto;
}
.css-106c1u2-MuiBadge-badge{
top:6px!important;
background-color:#013191!important
}

.sqdBtn{
  background-color: #013191;
  color: white;
  padding:10px 15px;
  font-size: 15px;
  font-weight: 500;
  border:0;
  cursor: pointer;
  border-radius:8px;
  display: flex;
  align-items: center;
  margin-right:10px;
}
.icon{
  margin:0 4px;
}
.sqdBtn:hover{
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 0.8em transparent;
}
.mainSqaudron{
  width: 100%;
}
.build{
background-color: #f1f0f0;
padding:10px 15px;
/* border-radius:8px; */
}
.mainBuiling{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buildNumber{
  margin:5px;
  font-size:15px;
  font-weight:500;
  display: flex;
  align-items: center;
}
.addBuild{
  margin-top:5px
}
.BuildMain{
  width:330px;
  background-color:#e3e9ee;
}
.buildInput{
  border:0;
  padding:10px 5px;
  border-radius:4px;
  margin:5px;
  background-color:#ffffff;
  width:80%
}
.buildInput:focus{
  outline:0;
}
.addBuildBtn{
  background-color: #013191;
  font-size:15px;
  font-weight:500;
  color: white;
  padding:5px 10px;
  margin:5px;
  border:0;
  border-radius:5px;
  cursor:pointer;
}
.addSquadronBtn{
  background-color: #013191;
  font-size:15px;
  font-weight:500;
  color: white;
  padding:10px 10px;
  margin:5px;
  border:0;
  border-radius:5px;
  cursor:pointer;
}
.sqdList {
  overflow-y: scroll;
}
.sqdList::-webkit-scrollbar {
  display: none;
}
.squadronTitle{
  color:#013191;
  font-size:18px; 
  font-weight:bold;
   /* margin:5px; */
   padding:5px;
   display: flex;
   align-items: center;
}

.selectedU{
  border:0;
  background-color:#6a91df60;
  color:#013191;
  padding:5px 10px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  transform: scale(1.1);
}
.notSelectedU{
  border:0;
  background-color:#013191;
  color:white;
  padding:5px 10px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(31, 115, 184, 0.564);
  }
}