.addQuestionModal{
    background-color: #e3e9ee!important;
    width:17rem!important;
    border: none;
    border-radius: 12px;
}
label{
    font-size:15px!important;
    font-weight:500!important;
    color:rgb(13, 51, 128)!important;
  }