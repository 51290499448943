.header{
    /* background-image: url('../../assets/faq.jpg'); */
    background-image: 
    linear-gradient(rgba(63, 76, 82, 0.23), #023047ed),url('../../assets/faq.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    border-bottom: 5px solid rgb(3, 20, 114);
    border-radius: 0;
    overflow-x: hidden;
}
.headerText{
    overflow-x: hidden;
    padding-left: 1rem;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    /* align-items: flex-start; */
    height: 250px;
    /* width:30%; */
    color: rgb(2, 18, 49);
}
