.mainContainerNavBAr{
    display: none;
    height: 60px;
    background-color: rgb(248, 248, 248);
    z-index:-1;
}
.navBarLogoSection{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navBarLogoSection1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 25px;
}
.navBarLogo{
    width: 40px;
    height: 40px;
    border-radius: 10px;
}
.toggleNavBar1{
    color: #40e0cf;
    cursor: pointer;
}
.toggleNavBar{
    color: #40e0cf;
    cursor: pointer;
}

.TextandToggleButton{
    background-color: #013191;
    width: 100%;
    height:60px;
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    z-index:5;
}
.TextandToggleButton1{
    background-color: #013191;
    width: 100%;
    height:60px;
    color: #ffffff;
    display: flex;
    /* justify-content:center; */
    align-items: center;
    position: fixed;

}
.NavBarText{
    color:white;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-left: 8px;
}

@media screen and (max-width: 580px) {
.mainContainerNavBAr{
    display: block;
}
  }
  