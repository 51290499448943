.css-480o17-MuiGrid-root>.MuiGrid-item{
    background-color: blueviolet!important;
}
.stats{
    background-color: red;
}
@media screen and (max-width:580px) {
    .stats{
        margin-top:1rem!important;
        border:'1px solid red'
    }
}