.footer{
    padding: 20px;
    width: 100%;
    /* position: absolute;
    bottom: 0;
    left: 0; */
    background-color: #013191;
    text-align: center;
    color: white;
    /* overflow-x: hidden; */
    /* position: absolute;
    right: 1px; */
    /* border: 1px solid yellow; */
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.footerContent{
    color: white;
    font-size:1.5rem;
    font-family: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}