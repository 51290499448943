.CategoryBtn{
/* border: 1px solid rgb(1, 1, 17)!important;
width: 100%!important;
text-align: left!important; */
}
/* #outlined-textarea{
    border-radius: 12px!important;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
    left:0!important;
    top:0!important
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #f8f8f8!important;
    color: rgb(0, 0, 0)!important;
} */

.addFaq{
    padding:0.5rem 1.5rem;
    border-radius:10px;
    margin-top:8px;
    border: none;
    font-size:15px;
    font-weight:600;
    background-color: #013191!important;
    color: white;
    margin:0.3rem 0.2rem ;
}
.addFaq:hover{
    cursor: pointer;
    /* background-color:rgb(228, 227, 227)!important;
    color:black; */
}