* {
  margin: 0;
  padding:0;
  text-decoration: none;
  box-sizing: border-box;
}
.container {
  display: flex;
  /* height: 100%; */
}
main {
  width: 100%;
}
.sidebar {
  background: #013191;
  color: #fff;
  width: 200px;
  transition: all 0.2s;
}

.logo {
  font-size: 30px;
}
/* .backArrow{
  margin-right:0.5rem;
} */
.bars {
  color: #fff;
  display: flex;
  font-size: 25px;
  /* margin-left: 10px; */
  transition: all 0.25s;
  /* border: 1px solid rgb(8, 8, 8); */
}
.bars:hover {
  color: #40e0cf;
  display: flex;
  font-size: 25px;
  /* margin-left: 50px; */
  transition: all 0.25s;
}
.link {
  display: flex;
  color: #fff;
  padding: 10px 10px;
  gap: 15px;
  transition: all 0.25s;
}
.link:hover {
  background: #40e0cf;
  color: #000;
  transition: all 0.25s;
}
.active {
  background: #40e0cf;
  color: #000;
}
.icon,
.linkText {
  font-size: 20px;
}
