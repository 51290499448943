.mainDivFeedBack{
  overflow-x: hidden;
}
.feedbackBlock {
  background-color: rgb(240, 240, 241);
}
.feedbackDetails{
    overflow-y: scroll;
    /* overflow-x: hidden; */
}
.FeedbackTitle{
    font-weight:700;
  margin-left: 1rem;
  font-size:1.5rem;
  }
table {
  padding: 0.2rem 0;
  width: 100% !important;
  border-collapse: collapse;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.5rem;
}

th {
  text-align: left;
  font-weight: 800;
  padding-left:5px;
  border-bottom: 1px solid #ddd;
  padding: 0.2rem 0;
  padding-left: 12px;
  font-size: 20px;
  color:#013191;
}
td {
  padding-left: 19px !important;
  padding: 0.2rem 0;
  font-weight: 400 !important;

  /* padding-left:7px; */
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-weight: 200;
  font-size: 16px;
}
.id {
  width: 4%;
  text-align:center
}
.request {
  width: 13%;
  text-align:center
}
.name {
  width: 12%;
  text-align:center
}
.building {
  width:10%;
  text-align:center
}
.suggestion {
  width:10%!important;
  text-align:center
}
.followUp {
  width: 10%;
  text-align:center
}
.anonymous {
  width: 8%;
  text-align:center
}
.actions {
  width: 10%;
  text-align: left;
}
.statusCol{
    width: 10%;
    border: 1px solid black;
    text-align:center
  }
.status {
    cursor: pointer;
}
.default tr:nth-child(even) {
  background-color: #dfdfdf;
}
.default tr:hover {
  background-color: rgb(255, 255, 255);
}
.default th {
  color: #0081b4;
}
.searchBoxandTabsf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin:5px 0px;
  }
  .tabsMainf {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .tabBtnf {
    font-weight: 500!important;
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
    font-size:larger!important;
    margin:0 5px;
  }
  .tabBtnf:hover {
    cursor: pointer;
  }
  
  .loader{
    display:flex;
    justify-content:center;
    align-items: center;
  }
  label{
    font-size:15px!important;
    font-weight:500!important;
    color:rgb(13, 51, 128)!important;
  }
  .selectedC{
    border:0;
    background-color:#6a91df60;
    color:#013191;
    padding:5px 10px;
    border-radius:8px;
    cursor: pointer;
    font-size: 17px;
    transform: scale(1.1);
  }
  .notSelectedC{
    border:0;
    background-color:#013191;
    color:white;
    padding:5px 10px;
    border-radius:8px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
  }
  .searchBarAndDelete{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .deleteAll{
    border:none;
    padding:15px 10px;
    text-align: center;
    background-color: #013191;
    color: white;
    font-size:15px;
    font-weight:bold;
    border-radius:10px;
    cursor: pointer;
    margin:0 10px;
    display: flex;
    align-items: center;
  }
  .deleteAll:hover{
    transition: all 0.5s ease-in-out;
    background-color: red;
  }
  .deleteIconF{
    margin:0 3px;
  } */

  .deleteAll {
    width: 150px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #013191;
    color: white;
    font-size:15px;
    font-weight:bold;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    margin:0 10px;
   }
   
   .deleteAll, .deleteAll span {
    transition: 200ms;
   }
   
   .deleteAll .text {
    transform: translateX(35px);
    color: white;
    font-weight: bold;
   }
   
   .deleteAll .icon {
    position: absolute;
    border-left: 1px solid #ffffff;
    transform: translateX(110px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   
   .deleteAll svg {
    width: 15px;
    fill: #eee;
   }
   
   .deleteAll:hover {
    background: #ff3636;
   }
   
   .deleteAll:hover .text {
    color: transparent;
   }
   
   .deleteAll:hover .icon {
    width: 150px;
    border-left: none;
    transform: translateX(0);
   }
   
   .deleteAll:focus {
    outline: none;
   }
   
   .deleteAll:active .icon svg {
    transform: scale(0.8);
   }