.mainDiv {
  background-color: rgb(240, 240, 241);
  overflow-x: hidden;
}
.categoryTitle {
  font-weight: 700;
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.5rem;
}
.borderActive:active {
  transform: translateY(4px) !important;
  border: 1px solid rgb(101, 116, 182) !important;
  border-radius: 12px !important;
}
.categoriesDetail {
  /* padding-left: 1.5rem !important; */
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  padding-bottom: 2rem !important;
  /* border: 1px solid blue!important; */
}
.categoryQA {
  /* border: 1px solid red!important; */
  overflow-x: hidden !important;
  /* padding-left: 1rem !important; */
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addQuestion {
  color: white !important;
}

.filterBtnsDiv {
  width: 100%;
}
.filterBtns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  /* margin-top:10px; */
}
.selected {
  border: 0;
  background-color: #6a91df60;
  color: #013191;
  padding: 10px 18px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
  transform: scale(1.2);
}
.notSelected {
  border: 0;
  background-color: #013191;
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.selectedDelete {
  color: #013191 !important;
  cursor: pointer !important;
  margin-right: 10px !important;
}
.notSelectedDelete {
  color: #ffffff !important;
  cursor: pointer !important;
  margin-right: 10px !important;
}

.selectedDiv {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  border-radius: 12px;
  font-weight: 500;
  font-size: 1.3rem;
  transition: transform 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6a91df60;
  color: #013191;
}
.notSelectedDiv {
  background-color: #013191;
  color:white;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  border-radius: 12px;
  font-weight: 500;
  font-size: 1.3rem;
  transition: transform 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
