.mainDivLogin{
    background-color: rgb(240, 240, 241);
    height: 40rem!important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.insideMain{
    width: 100%;
    height: 70%!important;
}
label{
    font-size:15px!important;
    font-weight:500!important;
    color:rgb(13, 51, 128)!important;
  }
.grid{
    height:100%!important;
    display: flex;
    justify-content: center;
}
.imgMain{
    height: 100%;
    background-color: #d1e1f0;
    padding:3rem 0;
}
.imgDiv{
    height:100%;
    /* border: 1px soli black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img{
    height:200px;
    border-radius: 12px;

}
.welcome{
    font-weight:700!important;
    color: #013191;
    /* margin-left:1rem!important; */
    align-self: center;
    margin-top: 0.5rem!important;
    /* font-size:23px; */
}

.pleaseLogin{
    text-align: left;
    margin-top: 0.5rem;

}
.textandIcon{
    display: flex;
    align-items: center;
}
.loginIcon{
    margin-top:0.8rem;
    margin-left: 0.5rem;
    animation: pulse 1s infinite;
    box-shadow: 0 0 0 1em transparent;
    color: blue;
    border-radius: 100%;
    cursor: pointer;
}
.formDiv{
    background-color: rgb(247, 247, 247);
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* border: 1px solid black; */
}
.loginForm{
    font-weight:700!important;
    color:#013191;
    padding-top:1rem;
    /* border: 1px solid black; */
    text-align: center;
    
}
.credentials{
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /* margin-left:1.5rem; */
}
.LoginBtnDiv{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:.3rem;
}
.LoginBtn{
    align-self: center!important;
    text-align: center!important;
    background-color: #013191!important;
    color:rgb(240, 240, 241)!important;
    font-weight:700!important;
    padding:0.5rem 0.5rem!important;
    font-size:medium!important;
    border-radius:12px!important;
    margin-top: 1rem!important;
    text-transform: capitalize!important;
    letter-spacing:2px!important;
    width:12rem!important;
}
.inputs{
/* border: 1px solid red; */
text-align: center;
}

.notRegistered{
display: flex;
padding-left:1.5rem;
margin-top:1.5rem;
}
.signUpLogin{
    margin-left:0.3rem;
    font-weight: 600;
    cursor: pointer;
    color: blue;
    border-radius:10%;
}
.signUpLogin:hover{
    animation: pulse 1s infinite;
    box-shadow: 0 0 0 1em transparent;
}
.forgetPassword{
margin-left: 10rem;
}
.forgetPassword:hover{
    color: red;
    cursor: pointer;
}
.error{
    color: red;
    font-size: medium;
    text-align: right;
    width: 68%;
}

.mobileViewLogoSection{
/* border: 1px solid black; */
padding-top: 10px;
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;
}
.mobileImgDiv{
    height: 90px;
    width: 90px;
}
.mobileViewLogo{
    height: 100%;
    width: 100%;
    border-radius:10px;
}
.mobileViewText p{
    font-size:25px;
    color:#013191;
    font-weight:700;
    letter-spacing: 1px;
    padding-left:15px;
}



@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(31, 115, 184, 0.564);
    }
  }

@media screen and (max-width:580px) {
    .imgMain{
        display: none!important;
    }
    /* .formDiv{
        display: none!important;
    } */
}
@media screen and (min-width:580px) {
    .mobileViewLogoSection{
        display: none!important;
    }
}