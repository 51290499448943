.masterClass{
    overflow-x: hidden!important;
}
.containerD{
    width: 100%!important;
    font-family: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* border: 3px solid red; */
    display: flex;
    /* flex-wrap: wrap; */
    background-color: rgb(248, 248, 248)!important;
    overflow-x: hidden!important;
}
.stats{
    width: 60%;
    background-color: rgb(248, 248, 248)!important;
    /* border: 2px solid yellow; */
}
.announcements{
    width: 40%;
    background-color: #40e0cf;
    /* border: 2px solid rgb(138, 2, 65); */
}
.stats{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* background-color: tomato; */
  }
.row {
  /* width: 29%; */
  box-sizing: border-box;
  padding:30px 45px;
  margin: 16px;
  border: 2px solid;
  border-color: #013191;
  border-radius: 25px;
  min-height:200px;
}
.numberAndArrow{
    font-size: 48px;
    font-weight: 900;
}

@media screen and (max-width: 900px) {
    .stats {
        width: 50%;
    }
    .announcements{
        width: 50%;
    }
  }
@media screen and (max-width: 600px) {
    .containerD{
        flex-wrap: wrap;
    }
    .stats {
        width: 100%;
    }
    .announcements{
        width: 100%;
    }
  }