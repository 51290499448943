.announcements{

    background-color:#013191!important;
    padding-top:1rem;
}
.announcementTitle{
    color:white;
    letter-spacing:1px;
    text-align: center;

}

.announcement{
    background-color: #40e0cf;
    margin: 4px;
    border-radius: 10px;
    padding: 16px;
    
}
.label{
    padding: 0;
}
.labelAndField{
    padding-left: 16px;
}
.scrollView{
    height: 450px;
    overflow-y: scroll;
}
.label{
    font-weight:bold;
}

.textInput{
    width: 70%;
    /* resize: vertical; */
    border: 0;
    padding:10px;
    /* box-shadow:0 0 15px 4px rgba(0,0,0,0.16); */
    border-radius:8px;
    font-family:inherit;
  font-size: inherit;
 
}
.buttonsSection{
    display: flex;
    flex-wrap: wrap;
}
.addBtn{
    padding:0.5rem 1.1rem;
    border-radius:8px;
    margin-top:8px;
    border: none;
    font-size:15px;
    font-weight:500;
    background-color: #013191;
    color: white;
    display:flex;
    justify-content: center;
    align-items: center;
}
.addBtn:hover{
    cursor: pointer;
    background-color: rgb(164, 164, 231);
    color: white;
}
.deleteBtn{
    padding:0.5rem 1rem;
    border-radius:10px;
    border: none;
    font-size:15px;
    font-weight:600;
    background-color: #013191   ;
    color: white;
    /* margin:0.5rem; */
    margin-left: 0.5rem;
}
.deleteBtn:hover{
    cursor: pointer;
    background-color:rgb(228, 227, 227);
    color:black;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addDiv{
border:2px solid #40e0cf;
padding: 5px 10px;
border-radius:8px;
}
.AnnouncementTitle{
    color:white;
    letter-spacing:1px;
}
.AnnouncementTitle:hover{
    color:#40e0cf;
    transition: all 0.5s;
    
}

.iconDiv{
    width:100%;
    text-align: right;
}

.icon{
    /* float:right; */
    cursor: pointer;
}