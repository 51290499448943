.mainContainer {
  /* height:41rem; */
  background-color: rgb(240, 240, 241);
  /* overflow-y: hidden; */
  /* margin-bottom: -1.2rem; */
}
.cardMain {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* height: 100%; */
  /* background-color: rgb(122, 68, 58); */
}
.orderTitle {
  font-weight: 700;
  margin-left: 1rem;
  /* padding-top: 1rem; */
  font-size: 1.5rem;
}
.cards {
  /* width: 29%; */
  box-sizing: border-box;
  padding: 48px;
  margin: 16px;
  /* min-height: 300px; */
  border: 2px solid;
  border-color: #013191;
  border-radius: 20px;
  align-items: center;
}
.ViewMore {
  cursor: pointer;
}
.refreshOrder {
  margin-right: 1rem;
  /* margin-bottom: 0.8rem; */
}
.orderList {
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.tabsMain {
  display: flex;
  padding-bottom: 1rem;
}
.tabBtn {
  font-weight: 500;
  padding-right: 0.75rem;
  font-size: smaller;
  margin:0 5px;

}
.tabBtn:hover {
  /* scale:0 0.5; */
  /* transform:translateX(1); */
  cursor: pointer;
  /* border-bottom: 1.5px solid blue; */
}

.orderDetails {
  overflow-x: auto;
}
table {
  padding: 0.2rem 0;
  width: 100% !important;
  border-collapse: collapse;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.5rem;
}

#orderTable th {
  text-align: left;
  font-weight: 800 !important;
  /* padding-left: 7px; */
  border-bottom: 1px solid #ddd;
  padding: 0.2rem 0;
  /* padding-left: 12px; */
  font-size: 20px !important;
  text-align: center;
}
td {
  padding-left: 19px !important;
  padding: 0.2rem 0;
  font-weight: 400 !important;

  /* padding-left:7px; */
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}
.id {
  width: 4%;
  text-align: center!important;
}
.address {
  width: 13%;
  text-align: center!important;
}
.price {
  width: 7%;
  text-align: center!important;
}
.name {
  width: 12%;
  text-align: center!important;
}
.keto {
  width: 7%;
  text-align: center!important;
}
.statusRow {
  width: 10%;
  text-align: center!important;
}
.actions {
  width: 10%;
  text-align: center!important;

  text-align: left;
}
.statusOrder {
  cursor: pointer;
  text-align: center!important;
}
.default tr:nth-child(even) {
  background-color: #dfdfdf;
}
.default tr:hover {
  background-color: rgb(255, 255, 255);
}
.default th {
  color: #013191;
}
.Actionicon {
  border-radius: 12px;
  background-color: #bad1c2;
  justify-content: center;
  text-align: center;
  display: flex;
}
label {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgb(13, 51, 128) !important;
}
.tick {
  justify-self: center;
}
.searchBoxandTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left:1rem;
}

.orderFilterBtn{
  display:flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width:80%;
}


.selected{
  border:0;
  background-color:#6a91df60;
  color:#013191;
  padding:10px 18px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  transform: scale(1.2);
}
.notSelected{
  border:0;
  background-color:#013191;
  color:white;
  padding:10px 15px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}
.selectedF{
  border:0;
  background-color:#6a91df60;
  color:#013191;
  padding:5px 10px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  transform: scale(1.1);
}
.notSelectedF{
  border:0;
  background-color:#013191;
  color:white;
  padding:5px 10px;
  border-radius:8px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 860px) {
  .orderList {
    letter-spacing: 0 !important;
  }
}
@media screen and (max-width: 800px) {
  .orderSearchBox {
    width: 15rem !important;
  }
}
